//import 'jquery';
//import "core-js";
import 'lodash.debounce';
import 'history';
import 'is_js';
import '@splidejs/splide/dist/js/splide';
import 'lightgallery.js';
import 'lg-zoom.js';
import 'pikaday';
import 'moment';
import 'choices.js';
import '@splidejs/splide';
import 'vendor/klaro-no-css';
import 'vendor/svgxuse';
